import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import CountdownTimer from './CountdownTimer';
import { __t } from './i18n';
import { IconPlay, IconInfo } from './icons';
import { mainBg } from './styles/Main';
import { Box, Button, CardImage, Flex, Icon, Link, Tag, Text } from './styles';
import { SpacingProps } from './withStyleSystem';
import { globalPadding } from './Layout/Layout';
import { WidgetItemSlider } from './apiService';
import { getNavigationUrl, getTimerDuration, inspectFlags, usePlayerService } from './utils';
import { Transition } from 'react-transition-group';

const delay = 500;

export default function SliderItem({ active, direction, serverTime, ...item }: SliderItemProps) {
  const images = {
    desktop: item.image_path,
    mobile: item.mobile_image_path,
  };
  return (
    <Transition in={active} timeout={{ enter: 0, exit: delay }} unmountOnExit>
      {(state) => (
        <Container style={{ ...getTransitionStyles(state, direction) }}>
          <Images as="div" images={images} />
          <GradientX />
          <GradientY />
          <SlideBody serverTime={serverTime} {...item} />
        </Container>
      )}
    </Transition>
  );
}

// -------------- //
// SUB-COMPONENTS //
// -------------- //

const SliderMainInfo = ({ logo, title, text, schedule }) => (
  <><Logo url={logo} mb={{ xs: 1, md: 3 }} />
    <SlideTitle data={title} schedule={schedule} mb={1} />
    <SlideText data={text} mb={{ xs: 2, md: 3, lg: 5 }} />
  </>
);

const SlideBody = ({ logo_image_path: logo, title, text, schedule_text: schedule,
  navigation_configuration: config, serverTime, slug, short_id, ...rest }: WidgetItemSlider) => {
  const href = getNavigationUrl(config, slug ? slug : title, short_id) || '';
  return <SlideInfo px={globalPadding}>
    {href && href === '#' ? <SliderMainInfo logo={logo} title={title} text={text} schedule={schedule} />
      : <Link href={href}>
        <SliderMainInfo logo={logo} title={title} text={text} schedule={schedule} />
      </Link>
    }
    <SlideActions title={title} serverTime={serverTime} navigation_configuration={config} href={href} short_id={short_id} {...rest} />
  </SlideInfo>;
};

const Logo = ({ url, ...rest }: { url?: string } & SpacingProps) => {
  return url ? <LogoImage {...rest} style={{ backgroundImage: `url(${url}?w=300)` }} /> : null;
};

const SlideTitle = ({ data, schedule, ...rest }: { data?: string; schedule?: string } & SpacingProps) => (
  <Flex direction={{ xs: 'column-reverse', sm: 'row' }} align={{ xs: 'flex-start', sm: 'center' }} {...rest}>
    <Text variant="h2" weight="bold">
      {data}
    </Text>
    {schedule && <Schedule text={schedule} />}
  </Flex>
);

const Schedule = ({ text }: { text: string }) => (
  <Tag my={1} mr={{ sm: 1 }} color="info">
    {text}
  </Tag>
);

const SlideText = ({ data, ...rest }: { data?: string } & SpacingProps) => (
  <Text weight="demibold" {...rest} style={{ opacity: 0.9 }}>
    {data}
  </Text>
);

const SlideActions = ({
  href,
  short_id,
  navigation_button: btn,
  navigation_configuration: config,
  show_play_button: playBtn,
  count_down: countdown,
  serverTime,
  flag,
  // carrier_video_content_button_text: carrierBtnText,
  // conditional_flag,
}: SlideActionsProps) => {
  const id = short_id ? short_id : config?.content_id || '';
  // const { hasSpecificCarrie } = inspectFlags(conditional_flag);

  const [timer, setTimer] = useState<number>();

  useEffect(() => {
    if (!countdown) return;
    setTimer(getTimerDuration(countdown, serverTime));
  }, [countdown, serverTime]);

  // const [handleWatch] = usePlayerService({ id, hasSpecificCarrie });
  const { free } = inspectFlags(flag || '');
  const [handleWatch] = usePlayerService({ id, free });
  const handlePlay = id && playBtn ? () => handleWatch('p') : undefined;
  // const buttonTitle = hasSpecificCarrie && carrierBtnText ? carrierBtnText : btn?.title;
  return (
    <Flex direction={{ xs: 'column', md: 'row' }} align={{ xs: 'flex-start', md: 'normal' }}>
      {timer && (
        <Box ml={2} mb={{ xs: 2, md: 0 }}>
          <CountdownTimer value={timer} />
        </Box>
      )}
      <Flex>
        {handlePlay && (
          <Button onClick={handlePlay} variant="primaryA" iconStart ml={2}>
            <Icon cursor="pointer">
              <IconPlay />
            </Icon>
            {btn?.title || __t('content.watch')}
          </Button>
        )}
        {!href || href === '#' ? null : <Button as={Link} href={href} variant="secondary" iconStart>
          <Icon cursor="pointer">
            <IconInfo />
          </Icon>
          {__t('common.more_info')}
        </Button>}
      </Flex>
    </Flex>
  );
};

// ------ //
// STYLES //
// ------ //

export const itemWrapperOffsetBottom = {
  xs: '-2.4rem',
  sm: '20%',
  md: '31%',
  lg: '36%',
};

const getTransitionStyles = (state: string, direction: 'right' | 'left') => {
  const common = {
    opacity: 1,
    transition: `transform ${delay}ms ease-in-out, opacity ${delay}ms ease-in-out`,
  };

  switch (state) {
    case 'entering':
      return {
        ...common,
        zIndex: 2,
        opacity: 0,
        transform: `translateX(${direction === 'right' ? '' : '-'}5%)`,
      };
    case 'entered':
      return {
        ...common,
        zIndex: 2,
        opacity: 1,
        transform: 'translateX(0)',
      };
    case 'exiting':
    case 'exited':
      return {
        ...common,
        zIndex: 0,
        opacity: 0,
        transform: `translateX(${direction === 'right' ? '-' : ''}5%)`,
      };
    default:
      return common;
  }
};

const Container = styled.div(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  background: 'no-repeat center url(/static/images/placeholder/slider-xs.svg)',
  backgroundSize: 'cover',
  [theme.breakpoints.up('sm')]: {
    backgroundImage: 'url(/static/images/placeholder/slider.svg)',
  },
}));

const responsiveImgSizes = {
  sm: 660,
  md: 1125,
  xl: 1920,
};

const Images = styled(CardImage)<{ images: SliderItemImages }>(({ theme, images }) => ({
  backgroundSize: 'cover',
  backgroundImage: `url(${images.mobile || images.desktop}?w=480)`,
  ...theme.mixins.create(responsiveImgSizes, (w) => ({
    backgroundImage: `url(${images.desktop}?w=${w})`,
  })),
}));

const GradientX = styled.div(({ theme }) => ({
  zIndex: 0,
  position: 'absolute',
  right: 0,
  left: 0,
  bottom: -1,
  height: '36%',
  background: `linear-gradient(to top, ${theme.palette.background[mainBg][0]}, rgba(0,0,0,0))`,
}));

const GradientY = styled.div(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    zIndex: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: '50%',
    background: `linear-gradient(to left, ${theme.palette.background[mainBg][0]}, rgba(0,0,0,0))`,
  },
}));

const SlideInfo = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  ...theme.mixins.create(itemWrapperOffsetBottom, (bottom) => ({ bottom })),
}));

const logoSizes = {
  xs: 90,
  md: 120,
};

const LogoImage = styled(Box)(({ theme }) => ({
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom right',
  ...theme.mixins.create(logoSizes, (height) => ({
    height: `${height / 16}rem`,
    width: `${(height / 16) * 2}rem`,
  })),
}));

// ----- //
// TYPES //
// ----- //

interface SliderItemProps extends WidgetItemSlider {
  active: boolean;
  direction: 'right' | 'left';
  serverTime?: undefined | string
}

export interface SliderItemImages {
  desktop: string;
  mobile?: string;
}

type SlideActionsProps = Pick<
  WidgetItemSlider,
  'navigation_button' | 'navigation_configuration' | 'show_play_button' | 'count_down' | 'title' | 'slug' | 'short_id' | 'carrier_video_content_button_text' | 'conditional_flag' | 'flag'
> & {
  serverTime?: string | undefined,
  href: string
};
